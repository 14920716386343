// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react'
import { isOpenLeftMenuType, leftMenuValueType, leftMenuItemValueType, isOpenLeftMenuItemType, isOpenTextType, isOpenTextValueType } from '../../redux/action_types';
import { useSelector, useDispatch } from 'react-redux'
import LeftMenuItem from '../Menu/LeftMenuItem';
import { left_menu_items } from '../../config/constants'
import { LogoIcon, LogoWithIcon } from '../icons';
export default function LeftMenu(props) {
    const trans = useSelector(state => state.trans)
    const dispatch = useDispatch();
    const size = useSelector(state => state.size)
    const user = useSelector(state => state.user)
    const theme = useSelector(state => state.theme)

    const toggleLeftMenu = () => {
        localStorage.setItem("toggle", JSON.stringify(!size.isOpenLeftMenu))
        localStorage.setItem("toggleText", JSON.stringify(!size.isOpenText))
        localStorage.setItem("toggleItem", JSON.stringify(!size.isOpenLeftMenuItem))
        animate();
        dispatch({ type: isOpenLeftMenuType })
    }
    const animate = () => {
        document.documentElement.classList.add("transition")
        dispatch({ type: isOpenLeftMenuItemType })
        setTimeout(() => {
            document.documentElement.classList.remove("transition")
            dispatch({ type: isOpenTextType })
            localStorage.setItem("toggleText", JSON.stringify(!size.isOpenText))
        }, 500);
    }
    useEffect(() => {
        if (localStorage.getItem("toggle") != null) {
            dispatch({ type: leftMenuValueType, payload: localStorage.getItem("toggle") === "true" ? true : false })
            dispatch({ type: leftMenuItemValueType, payload: localStorage.getItem("toggleItem") === "true" ? true : false })
            dispatch({ type: isOpenTextValueType, payload: localStorage.getItem("toggleText") === "true" ? true : false })
        }
    }, [])
    const menuRef = useRef();
    const [rightMenuOpen, setRightMenuOpen] = useState(true)
    return (
        <div ref={menuRef} className={size.isOpenLeftMenu ? "left_menu left_menu_open" : "left_menu"}
            style={(props.isPano && theme === "dark") ? { backgroundColor: "rgba(50,50,50,0.8)" } : { backgroundColor: "rgba(255,255,255,0.8)" }}
        >
            <div className={size.isOpenLeftMenu ? "logo logo_when_open" : "logo"}
                onClick={toggleLeftMenu}>
                {!size.isOpenLeftMenuItem ? <LogoWithIcon /> : <LogoIcon color={(theme === "dark" ? "#e5e5e5" : "#222222")} />}
            </div>
            <ul>
                {left_menu_items.map((m, i) => {
                    if (user && user.menus && user.menus.length > 0 && user.menus.some(x => x.path === m.path)) {
                        if (m.subs && m.subs.length > 0) {
                            var subs = [];
                            for (let lmi = 0; lmi < left_menu_items.length; lmi++) {
                                if (left_menu_items.some(a => a.path === m.path)) {
                                    for (let si = 0; si < left_menu_items[lmi].subs.length; si++) {
                                        if (m.subs.some(x => x.path === left_menu_items[lmi].subs[si].path)) {
                                            subs.push(left_menu_items[lmi].subs[si]);
                                        }
                                    }
                                }
                            }
                        }
                        return <LeftMenuItem
                            pathActive={m.pathActive}
                            path={m.path}
                            icon={m.icon}
                            name={m[trans.lang]}
                            active={m.active}
                            subs={subs}
                            key={i}
                            subOpen={left_menu_items.find(x => x.path === m.path).subOpen}
                            rightMenuOpen={rightMenuOpen}
                            setRightMenuOpen={setRightMenuOpen} />
                    }
                })}
            </ul>
        </div >
    )
}
