import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../anBrains/Input";
import { ManIcon, WomanIcon } from "../icons";
import Button from "../anBrains/Button";
import Avatar from "../anBrains/Avatar";
import { fetchEmployee, fetchTc } from "../../redux/actions/employee_ac";
import ContentLoader from "react-content-loader";
import { Select } from "antd";
import "antd/dist/antd.css";
import { itemType, tcType } from "../../redux/action_types";
import { fetchRoles } from "../../redux/actions/position_ac";
// import {customSelect2Styles} from '../../config/constants'
import man from "../../images/profiles/man.png";
import { dateFormatter, onlyDateFormatter } from "../../config/constants";
const { Option, OptGroup } = Select;
var defaultItem = {
  name: "",
  tc: "",
  birthdate: "",
  yearlyLeaveDate: "",
  photo: "",
  password: "",
  newPassword: "",
  email: "",
  phone: "",
  gender: false,
  hourlycost: 0,
  dailyCost: 0,
  yearlyLeaveCount: 0,
  roleIds: [],
};
export default function EmployeeForm(props) {
  const trans = useSelector((state) => state.trans);
  const current = useSelector((state) => state.current);
  const roles = useSelector((state) => state.position);
  const loader = useSelector((state) => state.loader);
  const result = useSelector((state) => state.result);
  const dispatch = useDispatch();
  const errors = {
    tcErr: "",
    nameError: "",
    emailError: "",
    roleError: "",
    mobileError: "",
    passwordError: "",
    passwordConfirmateError: "",
    birthdateError: "",
    yearlyLeaveDateError: "",
    hourlycostError: "",
    dailyCostError: "",
    yearlyLeaveCountError: "",
  };
  const [err, setErr] = useState(errors);

  useEffect(() => {
    current.item = defaultItem;
    current.item.birthdate = onlyDateFormatter(new Date());
    current.item.yearlyLeaveDate = dateFormatter(new Date());
    dispatch({ type: itemType, payload: current.item });
  }, []);
  const [message, setMessage] = useState("");
  let validate = () => {
    if (current.item.name.length < 3 || current.item.name.length > 50) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    // if (current.item.surname.length < 3 || current.item.name.length > 20) {
    //     setErr({ ...errors, surnameError: trans.toShort })
    //     return false
    // }

    // if (current.item.tc.length !== 11 || !current.tcconfirm) {
    //     setErr({ ...errors, tcErr: trans.pleaseEnterValidCitizenNo })
    //     setMessage(trans.pleaseEnterValidCitizenNo)
    //     return false
    // }

    if (current.item.roleIds.length === 0) {
      setErr({ ...errors, roleError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (current.item.birthdate === "" || current.item.birthdate === undefined) {
      setErr({ ...errors, birthdateError: trans.pleaseEnterValidDate });
      setMessage(trans.pleaseEnterValidDate);
      return false;
    }

    if (current.item.yearlyLeaveDate === "" || current.item.yearlyLeaveDate === undefined) {
      setErr({ ...errors, yearlyLeaveDateError: trans.pleaseEnterValidDate });
      setMessage(trans.pleaseEnterValidDate);
      return false;
    }

    if (!current.item.email.includes("@") || current.item.email.length > 50) {
      setErr({ ...errors, mailError: trans.pleaseEnterValidEmailAddress });
      setMessage(trans.pleaseEnterValidEmailAddress);
      return false;
    }

    // if (current.item.phone.length < 10 || current.item.phone.length > 30) {
    //     setErr({ ...errors, mobileError: trans.pleaseEnterValidPhoneAddress })
    //     setMessage(trans.pleaseEnterValidPhoneAddress)
    //     return false
    // }

    // if (current.item.hourlycost === 0) {
    //     setErr({ ...errors, hourlycostError: trans.pleaseEnterValid })
    //     setMessage(trans.pleaseEnterValid)
    //     return false
    // }

    // if (current.item.yearlyLeaveCount === 0) {
    //     setErr({ ...errors, yearlyLeaveCountError: trans.pleaseEnterValid })
    //     setMessage(trans.pleaseEnterValid)
    //     return false
    // }

    if (current.item.password.length < 5 || current.item.password.length > 30) {
      setErr({ ...errors, passwordError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (current.item.password !== current.item.newPassword) {
      setErr({ ...errors, passwordConfirmateError: trans.passwordsdonotmatch });
      setMessage(trans.passwordsdonotmatch);
      return false;
    }

    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(fetchEmployee(current.item, trans));
    }
  };
  const changeUrl = (url) => {
    current.item.photo = url;
    dispatch({ type: itemType, payload: current.item });
  };

  const blurHourlyCost = (val) => {
    current.item.hourlycost = val;
    dispatch({ type: itemType, payload: current.item });
  };

  const focusHourlyCost = (val) => {
    current.item.hourlycost = val;
    dispatch({ type: itemType, payload: current.item });
  };
  const selectMultiRoleIds = (roleIds) => {
    current.item.roleIds = roleIds;
    dispatch({ type: itemType, payload: current.item });
  };

  return !loader.loading ? (
    <div className="employeeForm">
      <Avatar changeUrl={changeUrl} defaultImg={man} />
      <Input
        placeholder={trans.name + ", " + trans.surname}
        name={trans.name}
        header={trans.name + ", " + trans.surname}
        value={current.item.name}
        onChange={(e) => {
          current.item.name = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.nameError}
        maxLength={30}
        minLength={3}
      />
      {/* <Input placeholder={trans.surname}
            name={trans.surname}
            header={trans.surname}
            value={current.item.surname}
            onChange={e => setField({ ...field, surname: e.target.value })}
            error={err.surnameError}
            maxLength={30}
            minLength={3} /> */}
      <Input
        placeholder={trans.citizenNo}
        type={"number"}
        name={trans.citizenNo}
        header={trans.citizenNo}
        value={current.item.tc}
        maxLength={11}
        minLength={11}
        onChange={(e) => {
          current.item.tc = e.target.value;
          dispatch({ type: itemType, payload: current.item });
          if (e.target.value.length === 11) {
            dispatch(fetchTc(e.target.value));
          } else {
            dispatch({ type: tcType, payload: false });
            setErr({ ...errors, tcErr: trans.pleaseEnterValidCitizenNo });
          }
        }}
        success={current.tcconfirm && current.item.tc.length === 11}
        error={!current.tcconfirm || current.item.tc.length !== 11 ? err.tcErr : ""}
      />
      <div className="mb-5">
        <span className="input_header">{trans.task}</span>
        {roles && roles.length > 0 ? (
          <Select
            mode="multiple"
            allowClear
            maxTagCount={3}
            maxTagTextLength={4}
            optionFilterProp="children"
            onChange={selectMultiRoleIds}
            style={
              current.item.roleIds && current.item.roleIds.length > 0
                ? { width: "100%" }
                : { width: "100%", borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }
            }
            placeholder={trans.roles}
            filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
            value={current.item.roleIds}
          >
            {roles.map((l, i) => {
              // //
              return (
                <Option key={"userd" + i} value={l.id}>
                  {l.name}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </div>
      <div className="gender">
        <Button
          icon={<ManIcon color={current.item.gender ? "#fff" : null} />}
          classes={current.item.gender ? "button dark" : "button"}
          onClick={() => {
            current.item.gender = true;
            dispatch({ type: itemType, payload: current.item });
          }}
        >
          {trans.man}
        </Button>
        <Button
          icon={<WomanIcon color={!current.item.gender ? "#fff" : null} />}
          classes={!current.item.gender ? "button dark" : "button"}
          onClick={() => {
            current.item.gender = false;
            dispatch({ type: itemType, payload: current.item });
          }}
        >
          {trans.woman}
        </Button>
      </div>
      <Input
        type={"date"}
        header={trans.birthdate}
        value={current.item.birthdate}
        onChange={(e) => {
          current.item.birthdate = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.birthdateError}
      />
      <Input
        placeholder={trans.email}
        name={trans.email}
        header={trans.email}
        value={current.item.email}
        maxLength={40}
        minLength={5}
        onChange={(e) => {
          current.item.email = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.mailError}
      />
      <Input
        placeholder={"5xxxxxxx"}
        // type={"number"}
        name={trans.mobile}
        header={trans.mobile}
        value={current.item.phone}
        maxLength={30}
        minLength={10}
        onChange={(e) => {
          current.item.phone = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.mobileError}
      />
      <Input
        placeholder={trans.hourlycost}
        type={"number"}
        name={trans.hourlycost}
        header={trans.hourlycost}
        value={current.item.hourlycost}
        min={0}
        max={1000}
        onChange={(e) => {
          current.item.hourlycost = parseFloat(e.target.value);
          dispatch({ type: itemType, payload: current.item });
        }}
        onFocus={(e) => focusHourlyCost(e.target.value)}
        onBlur={(e) => blurHourlyCost(e.target.value)}
        error={err.hourlycostError}
      />

      {/* <Input placeholder={trans.leavedaycount}
            type={"number"}
            name={trans.leavedaycount}
            header={trans.leavedaycount}
            value={current.item.yearlyLeaveCount}
            min={0}
            max={1000}
            onChange={e => setField({ ...field, yearlyLeaveCount: parseFloat(e.target.value) })}
            error={err.yearlyLeaveCountError}
        />
        <Input
            type={"date"}
            header={trans.yearlyHolidayStartDate}
            value={current.item.yearlyLeaveDate}
            // defaultValue={current.item.birthdate}
            onChange={e => setField({ ...field, yearlyLeaveDate: e.target.value })}
            error={err.yearlyLeaveDateError} /> */}

      {/* add daily cost inputs  */}
      <Input
        placeholder={trans.dailyCost}
        type={"number"}
        name={trans.dailyCost}
        // header={trans.dailycost}
        header={trans.dailyCost}
        value={current.item.dailyCost}
        min={0}
        max={1000}
        onChange={(e) => {
          current.item.dailyCost = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.dailyCostError}
      />

      <Input
        placeholder={trans.password}
        type={"password"}
        name={trans.password}
        header={trans.password}
        value={current.item.password}
        onChange={(e) => {
          current.item.password = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.passwordError}
      />
      <Input
        placeholder={trans.password}
        type={"password"}
        name={trans.confirmPassword}
        header={trans.confirmPassword}
        value={current.item.newPassword}
        minLength={5}
        maxLength={30}
        onChange={(e) => {
          current.item.newPassword = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.passwordConfirmateError}
      />

      {message && <span className="errorspan">{trans.error + ": " + message}</span>}
      <Button classes={"btn btn-primary"} onClick={register}>
        {trans.add}
      </Button>
    </div>
  ) : (
    <div className="employeeForm">
      <ContentLoader viewBox="0 0 250 600" height={600} width={275} speed={1} backgroundColor="#ddd" foregroundColor="#ecebeb" {...props}>
        <circle cx="150" cy="30" r="30" />
        <rect x="25" y="100" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="140" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="180" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="220" rx="3" ry="3" width="275" height="10" />
      </ContentLoader>
    </div>
  );
}
